<template>
    <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px" :color="color">
        <v-col cols="12" sm="6" md="4">
            <v-card v-if="status === 'producao'" class="d-flex mt-2 ml-2 mr-2"
                style="height: 150px; padding-right: 16px" :color="color">
                <v-col cols="1" style="background-color: #388E3C;" class="d-flex justify-center align-center">
                    <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                </v-col>
                <v-col cols="10">
                    <v-row class="d-flex flex-column align-start">
                        <small class="ml-4" style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                            {{ name }}
                        </small>
                    </v-row>
                    <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                        <v-col cols="12">
                            <v-card-title v-if="op"
                                class="d-flex justify-start align-end white--text font-weight-bold text-uppercase"
                                style="height: 90px; width: 115%;">
                                <strong
                                    style="font-size: 1em; background-color: #388E3C; color: white; padding: 5px 10px; border-radius: 5px; text-transform: uppercase;">
                                    ORDEM DE PRODUÇÃO: {{ op }} </strong>
                            </v-card-title>
                            <v-alert v-else type="warning" title="Atenção" style="height: 90px;width: 90%;"> <small
                                    style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;"> O.P NÃO
                                    INFORMADA </small> </v-alert>
                        </v-col>
                        <v-col cols="1">
                            <div class="d-flex flex-column align-end">
                                <small style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                                    <v-icon icon="mdi-timer"></v-icon>{{ tempExecucao }}
                                </small>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>

            <v-card v-else-if="status === 'parada'" class="d-flex mt-2 ml-2 mr-2"
                style="height: 150px; padding-right: 16px" :color="color">
                <v-col cols="1" style="background-color: #BF360C;" class="d-flex justify-center align-center">
                    <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                </v-col>
                <v-col cols="10">
                    <v-row class="d-flex flex-column align-start">
                        <small class="ml-4" style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                            {{ name }}
                        </small>
                    </v-row>
                    <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                        <v-col cols="12">
                            <v-card-title v-if="motivo_de_parada"
                                class="d-flex justify-start align-end white--text font-weight-bold text-uppercase"
                                style="height: 90px; width: 115%;">
                                <strong
                                    style="font-size: 1em; background-color: #EF233C; color: white; padding: 5px 10px; border-radius: 5px; text-transform: uppercase;">
                                    MOTIVO: {{ motivo_de_parada }}
                                </strong>
                            </v-card-title>
                            <v-alert v-else type="warning" title="Atenção"
                                style="height: 90px; width: 90%; color: #EF5350;">
                                <small style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                    M.P NÃO INFORMADA
                                </small>
                            </v-alert>
                        </v-col>
                        <v-col cols="1">
                            <div class="d-flex flex-column align-end">
                                <small style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                                    <v-icon icon="mdi-timer-cog"></v-icon>{{ tempExecucao }}
                                </small>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>

            <v-card v-else="status === 'offline'" class="d-flex mt-2 ml-2 mr-2"
                style="height: 150px; padding-right: 16px" :color="color">
                <v-col cols="1" style="background-color:#3C464E;" class="d-flex justify-center align-center">
                    <v-icon icon="mdi-lan-disconnect" size="40"></v-icon>
                </v-col>
                <v-col cols="10">
                    <v-row class="d-flex flex-column align-start">
                        <small class="ml-4" style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                            {{ name }}
                        </small>
                    </v-row>
                </v-col>
                <v-col cols="1">
                    <div class="d-flex flex-column align-end">
                        <small style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                            <v-icon icon="mdi-timer-alert"></v-icon>{{ tempExecucao }}
                        </small>
                    </div>
                </v-col>
            </v-card>
        </v-col>
    </v-card>
</template>

<script>

export default {
    name: "card_recursos_status",
    data: () => {
        return {
            count: 0,
        }
    },
    props: {
        name: String,
        op: String,
        status: String,
        dt_inicio: String,
        color: String,
        tempExecucao: String,
        motivo_de_parada: String,
    }
}
</script>